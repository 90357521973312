import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header/Header"
import Cheers from "../components/media/Cheers"
import GoogleMap from "../components/map"
import Instafeed from "../components/instafeed"
import ContactForm from "../components/contactform"
import MediaQuery from "react-responsive"
import BannerSmall from "../components/media/BannerSmall"

const IndexPage = () => (
  <Layout>
    <SEO title="Growlers and Cans Craft Beer Guildford" />
    <Header />
    <section className="Hero">
      <BannerSmall />
      <h2>
        craft beer taproom <br />& bottle shop
      </h2>
    </section>
    <main>
      <section>
        <div className="TwoColumn">
          <div className="textSection">
            <p>
              Growlers and Cans, Guildford's first and only specialist craft
              beer taproom and bottle shop, is an independent bar in the centre
              of town offering 6 ever changing brews on tap. Alongside an
              exemplary selection of local and worldwide wines, you can find an
              array of artisan gins, award winning mixers, and coffee from
              Guildford based roasters, Redber Coffee.
            </p>
          </div>
          <Cheers filename="soft_focus.jpg" />
        </div>
      </section>
      <section>
        <div className="TwoColumn even">
          <Cheers filename="gin.jpg" />
          <div className="textSection">
            <p>
              The brain child of husband and wife team, Charlie and Maxine,
              Growlers and Cans offers a relaxing environment to kick back and
              enjoy a casual drink with fellow beer lovers. More than just a
              bar, an impressive selection of bottles and cans are on offer for
              you to try at home, or why not purchase one of our Growlers for
              craft beer on the go.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="TwoColumn">
          <div className="textSection">
            <p>
              Growlers are happy for you to bring your own food or takeaway to
              eat at the bar. Why not order your favourite Deliveroo to eat
              alongside your favourite beer?
            </p>
          </div>
          <Cheers filename="coffee.jpg" />
        </div>
      </section>
      <section>
        <MediaQuery maxWidth={899}>
          <div className="textSection centered">
            <WhereToFindUs />
          </div>
          <GoogleMap />
          <div className="textSection centered">
            <OpeningHours />
            <p>Cards Only</p>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={900}>
          <div className="TwoColumn">
            <GoogleMap />
            <div className="textSection">
              <WhereToFindUs />
              <OpeningHours />
              <p>Cards Only</p>
            </div>
          </div>
        </MediaQuery>
      </section>
      {/* <section>
        <div className="OneColumn">
          <h2>Coming Up at G&C</h2>
          <Events />
        </div>
      </section> */}
      <section>
        <div className="OneColumn">
          <h2>Growler's Gram</h2>
          <Instafeed />
        </div>
      </section>
      <section>
        <div className="OneColumn">
          <h2>Any questions?</h2>
          <p>Fill out the form below and we will get back to you ASAP</p>
          <ContactForm />
        </div>
      </section>
    </main>
  </Layout>
)

const OpeningHours = () => (
  <>
    <h3>Opening Hours</h3>
    <table>
      <tbody>
        <tr>
          <td>Monday</td>
          <td>CLOSED</td>
        </tr>
        <tr>
          <td>Tuesday</td>
          <td>4 - 10 PM</td>
        </tr>
        <tr>
          <td>Wednesday</td>
          <td>4 - 10 PM</td>
        </tr>
        <tr>
          <td>Thursday</td>
          <td>4 - 10 PM</td>
        </tr>
        <tr>
          <td>Friday</td>
          <td>4 - 10 PM</td>
        </tr>
        <tr>
          <td>Saturday</td>
          <td>12 - 10 PM</td>
        </tr>
        <tr>
          <td>Sunday</td>
          <td>CLOSED</td>
        </tr>
      </tbody>
    </table>
  </>
)

const WhereToFindUs = () => (
  <>
    <h3>Where to Find Us</h3>
    <p>
      24 Chertsey Street <br />
      Guildford <br />
      GU1 4HD <br />
    </p>
  </>
)

export default IndexPage

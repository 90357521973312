import React from "react"

import "./contactform.css"

const ContactForm = () => {
  return (
    <div className="ContactForm">
      <form
        method="post"
        name="contact"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <label for="name">Name</label>
        <input type="text" name="name" id="name" required />

        <label for="email">Email</label>
        <input type="email" name="email" id="email" required />

        <label for="message">Message</label>
        <textarea
          name="message"
          id="message"
          rows="5"
          placeholder="Type your message here..."
          required
        />

        <button type="submit">Send</button>
      </form>
    </div>
  )
}

export default ContactForm

import React from "react"

const GoogleMap = () => {
  return (
    <div className="mapContainer">
      <iframe
        title="map"
        height="100%"
        style={{minHeight: 400}}
        width="100%"
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA2moQqxedAXaqVRTtVn7xcbJ6u-I18OPg&q=Growlers+and+cans,Guildford"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default GoogleMap

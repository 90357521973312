import "./instafeed.css"
import React, { useEffect } from "react"
import { withPrefix } from "gatsby"

const Instafeed = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = withPrefix("insta.js")
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <div className="InstaFeed" id="curator-feed">
      <a
        href="https://curator.io"
        target="_blank"
        rel="noopener noreferrer"
        className="crt-logo crt-tag"
      >
        Powered by Curator.io
      </a>
    </div>
  )
}

export default Instafeed
